import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    marginTop: "20px",
    marginBottom: "60px",
  },
  testimonialArea: {
    display: "flex",
    padding: "20px",
    [theme.breakpoints.down("xs")]: {
      margin: "20px 20px",
    },
    [theme.breakpoints.up("sm")]: {
      margin: "20px 100px",
    },
    [theme.breakpoints.up("md")]: {
      margin: "20px 200px",
    },
  },
  avatar: {
    marginRight: "20px",
    display: "block",
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
}))
