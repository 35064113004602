import React from "react"
import { Paper, Box, Typography, Avatar } from "@material-ui/core"

import Image from "../Image/image"
import { useStyles } from "./style/testimonialStyle"

const TestimonialList = ({ displayName, photoURL, children }) => {
  const classes = useStyles()

  return (
    <Paper className={classes.testimonialArea}>
      <Box className={classes.testimonialBox}>
        <Avatar alt={displayName} className={classes.avatar}>
          <Image assetUrl={photoURL} alt={displayName} />
        </Avatar>
      </Box>
      <Box className={classes.testimonialBox}>
        <Typography variant="body1">{children}</Typography>
      </Box>
    </Paper>
  )
}

export default TestimonialList
