import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
  },
  titleBar: {
    padding: "1rem 1rem 1rem 1rem",
    color: "#ffffff",
    backgroundColor: "#ff5722",
    textAlign: "center",
    borderRadius: 0,
  },
  triangleBox: {
    position: "relative",
    left: "50%",
    transform: "translateX(-50%)",
    width: 0,
    height: 0,
    borderTop: "30px solid #ff5722",
    borderRight: "20px solid transparent",
    borderLeft: "20px solid transparent",
  },
  circleArea: {
    padding: "1rem 1rem 1rem 1rem",
    textAlign: "center",
    borderRadius: "50%",
  },
  image: {
    margin: 0,
  },
}))
