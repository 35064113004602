import React from "react"

import { Grid, Box } from "@material-ui/core"
import { MuiThemeProvider } from "@material-ui/core/styles"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Problem from "../components/Problem/problem"
import Interest from "../components/Interest/interest"
import Production from "../components/Production/production"
import Testimonial from "../components/Testimonial/testimonial"
import Suppliers from "../components/Suppliers/suppliers"
import CallToAction from "../components/CallToAction/callToAction"

import { Theme } from "../components/style/theme"
import { useStyles } from "../components/style/indexStyle"

const IndexPage = () => {
  const classes = useStyles()

  return (
    <MuiThemeProvider theme={Theme}>
      <Layout page={"index"}>
        <SEO title="共進社印刷 WEBサイト制作" />
        <Grid container justify="center">
          <Grid item xs={12}>
            <Problem />
          </Grid>
          <Grid item xs={12}>
            <Interest />
          </Grid>
          <Grid item xs={12}>
            <Production />
          </Grid>
          <Grid item xs={12}>
            <Testimonial />
          </Grid>
          <Grid item xs={12}>
            <Suppliers />
          </Grid>
          <Grid item xs={12}>
            <CallToAction />
          </Grid>
        </Grid>
        <Box className={classes.pageEnd} />
      </Layout>
    </MuiThemeProvider>
  )
}

export default IndexPage
