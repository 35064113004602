import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  root: {
    marginTop: "30px",
    marginBottom: "30px",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
  },
  gridList: {
    width: "80%",
  },
  icon: {
    color: "rgba(255, 255, 255, 0.54)",
  },
  prodTile: {
    cursor: "pointer",
  },
  prodImage: {
    transitionDuration: "0.5s",
    "&:hover": {
      transform: "scale(1.1)",
      transitionDuration: "0.5s",
    },
  },
}))
