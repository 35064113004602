import React from "react"
import { Paper, Box, Typography } from "@material-ui/core"

import { useStyles } from "./style/commonStyle"

const Suppliers = ({ title }) => {
  const classes = useStyles()

  return (
    <>
      <Paper elevation={3} className={classes.titleBar}>
        <Typography variant="h4">{title}</Typography>
      </Paper>
      <Box className={classes.triangleBox}></Box>
    </>
  )
}

export default Suppliers
