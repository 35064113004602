import React from "react"
import { Grid, Box, Typography } from "@material-ui/core"
import {
  DesktopAccessDisabled as OldPcIcon,
  MoodBad as MoodBadIcon,
  PermDeviceInformation as NoSmartIcon,
  LiveHelp as NonViewIcon,
} from "@material-ui/icons"

import ProblemPoint from "./problemPoint"
import { useStyles } from "./style/problemStyle"

const Problem = props => {
  const classes = useStyles()

  return (
    <Grid container justify="center">
      <Grid item xs={12}>
        <Box className={classes.problemTitle}>
          <Typography variant="h3">
            貴教室のWEBサイト
            <br />
            こんな事ありませんか？
          </Typography>
        </Box>
      </Grid>

      <Grid item lg={3} md={6} sm={6} xs={12}>
        <ProblemPoint
          icon={""}
          content={"見た目が古い"}
          circleStyle={classes.pointOld}
          optionStyle={{ marginTop: "30px" }}
        >
          <OldPcIcon className={classes.bigIcon} />
        </ProblemPoint>
      </Grid>
      <Grid item lg={3} md={6} sm={6} xs={12}>
        <ProblemPoint
          icon={""}
          content={"担当者一人しか編集できない"}
          circleStyle={classes.pointOneOperation}
        >
          <MoodBadIcon className={classes.bigIcon} />
        </ProblemPoint>
      </Grid>
      <Grid item lg={3} md={6} sm={6} xs={12}>
        <ProblemPoint
          icon={""}
          content={"スマートフォンに対応できていない"}
          circleStyle={classes.pointNonResponsive}
        >
          <NoSmartIcon className={classes.bigIcon} />
        </ProblemPoint>
      </Grid>
      <Grid item lg={3} md={6} sm={6} xs={12}>
        <ProblemPoint
          icon={""}
          content={"ホームページが見られているのか分からない"}
          circleStyle={classes.pointNonAnalysis}
        >
          <NonViewIcon className={classes.bigIcon} />
        </ProblemPoint>
      </Grid>

      <Grid item xs={12}>
        <Box className={classes.solution}>
          <Typography variant="h5">お困りのその問題、解決致します！</Typography>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box className={classes.problemEnd}>
          <Typography variant="h5">
            コロナ禍で
            <span className={classes.typoStrong}>ウェブサイトの重要度</span>
            が高まっています！
          </Typography>
        </Box>
      </Grid>
    </Grid>
  )
}

export default Problem
