import { makeStyles } from "@material-ui/core/styles"
import { pxToRem } from "../../Common/utility"

export const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
  },
  listIcon: {
    marginTop: "8px",
    color: "#ff5722",
    minWidth: "32px",
  },
  listItemText: {
    "& span": {
      color: "#009688",
      fontWeight: "bold",
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.8rem",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "2rem",
      },
    },
  },
  nestedList: {
    paddingLeft: theme.spacing(6),
  },
  nestedListItem: {
    listStyleType: "none",
    listStylePosition: "outside",
    display: "flex",
    "&::before": {
      [theme.breakpoints.down("xs")]: {
        fontSize: pxToRem(20),
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: pxToRem(24),
      },
      [theme.breakpoints.up("md")]: {
        fontSize: pxToRem(26),
      },
      content: '"・"',
    },
  },
  imageBox: {
    margin: "14px",
  },
}))
