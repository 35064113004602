import React from "react"
import {
  Grid,
  Box,
  GridList,
  GridListTile,
  GridListTileBar,
  Link,
} from "@material-ui/core"
import withWidth, { isWidthUp } from "@material-ui/core/withWidth"

import Image from "../Image/image"
import TitleBar from "../Common/titleBar"
import { useStyles } from "./style/productionStyle"

const tileData = [
  {
    id: 1,
    img: "web_affas.png",
    alt: "Asian Federation of Foot and Ankle Surgeons",
    title: "Asian Federation of Foot and Ankle Surgeons",
    url: "https://www.affas.org",
  },
  {
    id: 2,
    img: "web_nmurm.png",
    alt: "奈良県立医科大学 呼吸器内科学講座",
    title: "奈良県立医科大学 呼吸器内科学講座",
    url: "https://naramed-respiratory.jp/",
  },
  {
    id: 3,
    img: "web_nmud.png",
    alt: "奈良県立医科大学 糖尿病・内分泌内科学講座",
    title: "奈良県立医科大学 糖尿病・内分泌内科学講座",
    url: "https://naramed-endocrinology.jp/",
  },
  {
    id: 4,
    img: "web_afjo.png",
    alt: "Congrès AFJO 2021 Association France-Japon d'Orthopédie",
    title: "Congrès AFJO 2021 Association France-Japon d'Orthopédie",
    url: "https://www.c-linkage.co.jp/afjo2021/",
  },
  {
    id: 5,
    img: "web_jcor.png",
    alt: "第30回日本リウマチ学会近畿支部学術集会",
    title: "第30回日本リウマチ学会近畿支部学術集会",
    url: "https://naraseikei.com/kra2021/",
  },
]

const Production = props => {
  const classes = useStyles()

  const getGridListCols = () => {
    if (isWidthUp("xl", props.width)) {
      return 4
    }
    if (isWidthUp("lg", props.width)) {
      return 3
    }
    if (isWidthUp("md", props.width)) {
      return 2
    }
    if (isWidthUp("sm", props.width)) {
      return 1
    }
    return 1
  }

  return (
    <>
      <Grid container justify="center">
        <Grid item xs={12}>
          <Box id="production">
            <TitleBar title={"制作実績"} />
          </Box>
        </Grid>
      </Grid>

      <div className={classes.root}>
        <GridList
          cellHeight={360}
          cols={getGridListCols()}
          spacing={10}
          className={classes.gridList}
        >
          {tileData.map(tile => (
            <GridListTile key={tile.id} className={classes.prodTile}>
              <Link href={tile.url} rel="noopener noreferrer" target="_blank">
                <Image
                  className={classes.prodImage}
                  assetUrl={tile.img}
                  alt={tile.alt}
                />
                <GridListTileBar title={tile.title} />
              </Link>
            </GridListTile>
          ))}
        </GridList>
      </div>
    </>
  )
}

export default withWidth()(Production)
