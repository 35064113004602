import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
  },
  actionBox: {
    marginTop: "40px",
    marginBottom: "40px",
    textAlign: "center",
    color: "#00796b",
    margin: "20px",
  },
  companyInfo: {
    textAlign: "center",
    marginBottom: "50px",
  },
}))
