import React from "react"
import { Grid, Box } from "@material-ui/core"

import TitleBar from "../Common/titleBar"
import TestimonialList from "./testimonialList"

import { useStyles } from "./style/testimonialStyle"

const Testimonial = props => {
  const classes = useStyles()

  return (
    <Grid container justify="center" className={classes.root}>
      <Grid item xs={12}>
        <Box>
          <TitleBar title={"お客様の声"} />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <TestimonialList
          displayName={"professor_1"}
          photoURL={"professor_1.png"}
        >
          教室の見学依頼などもあり、効果を実感しています。
        </TestimonialList>
      </Grid>
      <Grid item xs={12}>
        <TestimonialList
          displayName={"professor_2"}
          photoURL={"professor_2.png"}
        >
          アクセス解析を見ると若手医師の声なども結構見て頂いていることもわかり、
          コンテンツの追加をしようと思います。
        </TestimonialList>
      </Grid>
    </Grid>
  )
}

export default Testimonial
