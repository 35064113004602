import React from "react"
import {
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
} from "@material-ui/core"
import { ArrowRight as ArrowRightIcon } from "@material-ui/icons"

import { useStyles } from "./style/interestStyle"

const InterestList = ({ title, contents }) => {
  const classes = useStyles()

  return (
    <Box className={classes.circleContent}>
      <List>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <ArrowRightIcon fontSize="large" />
          </ListItemIcon>
          <ListItemText className={classes.listItemText} primary={title} />
        </ListItem>
        <ul className={classes.nestedList}>
          {contents.map(item => (
            <li key={item} className={classes.nestedListItem}>
              <Typography variant="body1">{item}</Typography>
            </li>
          ))}
        </ul>
      </List>
    </Box>
  )
}

export default InterestList
