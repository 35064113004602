import React from "react"
import { Grid, Box, Divider } from "@material-ui/core"

import Image from "../Image/image"
import TitleBar from "../Common/titleBar"
import InterestList from "./interestList"
import { useStyles } from "./style/interestStyle"

const listItem = {
  first: [
    "Web ブラウザで更新できるので、どこからでも更新することが可能",
    "複数のスタッフで分業ができる",
    "Facebook やその他のSNS との連携がカンタン",
  ],
  second: [
    "どんな端末の画面でも見やすい",
    "アドレスが1つなので、ユーザーがリンク・共有をしやすい",
  ],
  third: [
    "ページ閲覧数や訪問者数が分かる",
    "どこから、どのようなキーワードでアクセスされたかが分かるので、ニーズに応えるサイトに改善していく事ができる",
  ],
}

const Interest = props => {
  const classes = useStyles()

  return (
    <Grid container spacing={3} justify="center">
      <Grid item xs={12}>
        <Box>
          <TitleBar title={"共進社印刷のWEBサイト制作"} />
        </Box>
      </Grid>

      <Grid item sm={8} xs={12}>
        <InterestList
          title={"お客様自身でサイト更新作業ができる"}
          contents={listItem.first}
        />
      </Grid>
      <Grid item sm={4} xs={12}>
        <Box className={classes.imageBox}>
          <Image assetUrl="interest_updating.png" alt="サイト更新イメージ" />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Divider variant="middle" />
      </Grid>

      <Grid item sm={8} xs={12}>
        <InterestList title={"スマホに対応"} contents={listItem.second} />
      </Grid>
      <Grid item sm={4} xs={12}>
        <Box className={classes.imageBox}>
          <Image assetUrl="interest_3type.png" alt="レスポンシブイメージ" />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Divider variant="middle" />
      </Grid>

      <Grid item sm={8} xs={12}>
        <InterestList
          title={"アクセス解析でより効果の高いサイトへ"}
          contents={listItem.third}
        />
      </Grid>
      <Grid item sm={4} xs={12}>
        <Box className={classes.imageBox}>
          <Image assetUrl="interest_analysis.png" alt="アクセス解析イメージ" />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Divider variant="middle" />
      </Grid>
    </Grid>
  )
}

export default Interest
