import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    marginTop: "20px",
    marginBottom: "20px",
  },
  suppliersTitle: {
    padding: "1rem 1rem 1rem 1rem",
    color: "#ffffff",
    backgroundColor: "#607d8b",
    textAlign: "center",
    borderRadius: 0,
  },
  suppliersBox: {
    margin: "40px 20px 40px 20px",
  },
  suppliersItem: {
    margin: "10px 20px 10px 20px",
  },
}))
