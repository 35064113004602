import { createMuiTheme } from "@material-ui/core/styles"
import createBreakpoints from "@material-ui/core/styles/createBreakpoints"
import { teal, pink } from "@material-ui/core/colors"
import { pxToRem } from "../Common/utility"

const breakpoints = createBreakpoints({})

// Material UIのテーマ上書き
export const Theme = createMuiTheme({
  // カラーパレット追加
  palette: {
    primary: teal,
    secondary: pink,
  },
  typography: {
    fontFamily: "'M PLUS 1p', 'Segoe UI', Roboto, 'Helvetica Neue', sans-serif",
  },
  overrides: {
    MuiTypography: {
      h1: {
        [breakpoints.down("xs")]: {
          fontSize: pxToRem(20),
        },
        [breakpoints.up("sm")]: {
          fontSize: pxToRem(24),
        },
        [breakpoints.up("md")]: {
          fontSize: pxToRem(28),
        },
      },
      h2: {
        [breakpoints.down("xs")]: {
          fontSize: pxToRem(20),
        },
        [breakpoints.up("sm")]: {
          fontSize: pxToRem(24),
        },
        [breakpoints.up("md")]: {
          fontSize: pxToRem(28),
        },
      },
      h3: {
        [breakpoints.down("xs")]: {
          fontSize: pxToRem(36),
        },
        [breakpoints.up("sm")]: {
          fontSize: pxToRem(38),
        },
        [breakpoints.up("md")]: {
          fontSize: pxToRem(42),
        },
      },
      h4: {
        [breakpoints.down("xs")]: {
          fontSize: pxToRem(30),
        },
        [breakpoints.up("sm")]: {
          fontSize: pxToRem(34),
        },
        [breakpoints.up("md")]: {
          fontSize: pxToRem(36),
        },
      },
      h5: {
        [breakpoints.down("xs")]: {
          fontSize: pxToRem(28),
        },
        [breakpoints.up("sm")]: {
          fontSize: pxToRem(30),
        },
        [breakpoints.up("md")]: {
          fontSize: pxToRem(32),
        },
      },
      h6: {
        [breakpoints.down("xs")]: {
          fontSize: pxToRem(20),
        },
        [breakpoints.up("sm")]: {
          fontSize: pxToRem(20),
        },
        [breakpoints.up("md")]: {
          fontSize: pxToRem(26),
        },
      },
      subtitle1: {
        [breakpoints.down("xs")]: {
          fontSize: pxToRem(20),
        },
        [breakpoints.up("sm")]: {
          fontSize: pxToRem(20),
        },
        [breakpoints.up("md")]: {
          fontSize: pxToRem(22),
        },
      },
      subtitle2: {
        [breakpoints.down("xs")]: {
          fontSize: pxToRem(18),
        },
        [breakpoints.up("sm")]: {
          fontSize: pxToRem(20),
        },
        [breakpoints.up("md")]: {
          fontSize: pxToRem(24),
        },
      },
      body1: {
        [breakpoints.down("xs")]: {
          fontSize: pxToRem(20),
        },
        [breakpoints.up("sm")]: {
          fontSize: pxToRem(24),
        },
        [breakpoints.up("md")]: {
          fontSize: pxToRem(26),
        },
      },
      body2: {
        [breakpoints.down("xs")]: {
          fontSize: pxToRem(22),
        },
        [breakpoints.up("sm")]: {
          fontSize: pxToRem(22),
        },
        [breakpoints.up("md")]: {
          fontSize: pxToRem(22),
        },
      },
      button: {
        [breakpoints.down("xs")]: {
          fontSize: pxToRem(18),
        },
        [breakpoints.up("sm")]: {
          fontSize: pxToRem(20),
        },
        [breakpoints.up("md")]: {
          fontSize: pxToRem(24),
        },
      },
    },
  },
})
