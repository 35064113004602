import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
  },
  problemTitle: {
    textAlign: "center",
    color: "#6c6f38",
  },
  problemEnd: {
    textAlign: "center",
    color: "#6c6f38",
    margin: "60px",
  },
  circleArea: {
    padding: "1rem 1rem 1rem 1rem",
    margin: "2rem auto 2rem auto",
    width: "250px",
    height: "250px",
    textAlign: "center",
    borderRadius: "50%",
  },
  circleContent: {
    width: "85%",
    margin: "2rem auto 2rem auto",
  },
  bigIcon: {
    marginTop: "-10px",
    marginBottom: "6px",
    fontSize: "3em",
  },
  circleMessage: {
    height: "150px",
  },
  pointOld: {
    backgroundColor: "#3f51b5",
    color: "#ffffff",
  },
  pointOneOperation: {
    backgroundColor: "#f9a825",
    color: "#ffffff",
  },
  pointNonResponsive: {
    backgroundColor: "#26a69a",
    color: "#ffffff",
  },
  pointNonAnalysis: {
    backgroundColor: "#ec407a",
    color: "#ffffff",
  },
  solution: {
    padding: "1rem 1rem 1rem 1rem",
    color: "#ffffff",
    backgroundColor: "#009688",
    textAlign: "center",
    borderRadius: 0,
  },
  typoStrong: {
    fontWeight: "bold",
  },
}))
