import React from "react"
import { Paper, Box, Typography } from "@material-ui/core"

import { multiClass } from "../Common/utility"
import { useStyles } from "./style/problemStyle"

const ProblemPoint = ({ content, circleStyle, children, optionStyle }) => {
  const classes = useStyles()

  return (
    <Paper className={multiClass([classes.circleArea, circleStyle])}>
      <Box className={classes.circleContent}>
        {children}
        <Box className={classes.circleMessage} style={optionStyle}>
          <Typography variant="body2">{content}</Typography>
        </Box>
      </Box>
    </Paper>
  )
}

export default ProblemPoint
