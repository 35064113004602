import React from "react"
import { Grid, Box, Typography, Paper, Divider } from "@material-ui/core"

import { useStyles } from "./style/suppliersStyle"

const suppliersItem = [
  "大阪大学",
  "京都大学",
  "神戸大学",
  "奈良県立医科大学",
  "大阪公立大学",
  "大阪医科薬科大学",
  "国立循環器病研究センター",
  "大阪市立総合医療センター",
  "JCHO 大阪病院",
]

const Suppliers = props => {
  const classes = useStyles()

  return (
    <Grid container justify="flex-start" className={classes.root}>
      <Grid item xs={12}>
        <Paper elevation={3} className={classes.suppliersTitle}>
          <Typography variant="h4">主な取引先</Typography>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Box className={classes.suppliersBox}>
          <Grid container justify="flex-start">
            {suppliersItem.map(item => (
              <Grid item md={3} sm={6} xs={12} key={item}>
                <Box className={classes.suppliersItem}>
                  <Typography variant="subtitle1">{item}</Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  )
}

export default Suppliers
