import React from "react"
import { Grid, Box, Typography } from "@material-ui/core"

import { useStyles } from "./style/callToActionStyle"

const Suppliers = props => {
  const classes = useStyles()

  return (
    <Grid container justify="center">
      <Grid item xs={12}>
        <Box className={classes.actionBox}>
          <Typography variant="h5">
            どんなことでも構いません。お気軽にお問い合わせください！
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box className={classes.companyInfo}>
          <Typography variant="h6">共進社印刷株式会社</Typography>
          <Typography variant="body1">
            〒540-0003 大阪市中央区森ノ宮中央2-9-5
            <br />
            Tel.06-6941-8881 Fax.06-6941-1053
          </Typography>
        </Box>
      </Grid>
    </Grid>
  )
}

export default Suppliers
